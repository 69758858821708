export function newCampanha() {
    return {
        nome: '',
        budget: '',
        company_id: 0,
        fb_code: '',
        campanha_id: '',
        account_selected: '',
        is_active: false,
        page_name: ['', '', ''],
    };
}
